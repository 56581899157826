* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: #fff;
}

html,
body {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background: #000;
}

h1.header {
	font-family: "Bigilla" !important;
	font-size: 200px;
	text-transform: uppercase;
	font-weight: bolder;
	width: 90%;
}

.header .letter {
	display: inline-block;
	line-height: 1em;
}

.titles {
    opacity: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

h1 {
	font-weight: 500;
	font-size: 240px;
	font-family: "Barracuda" !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

ul.titles {
	display: flex;
}

ul.blocks {
	margin: 0;
	padding: 0;
	display: flex;
	width: 100%;
	height: 100vh;
	list-style: none;
	justify-content: center;
	align-items: center;
}

li.block {
	height: 100vh;
}

.block-1 {
	width: 0.2%;
	margin: 0 10px;
	background: #da0510;
}

.block-2 {
	width: 1.4%;
	margin: 0 200px 0 20px;
	background: #2001fe;
}

.block-3 {
	width: 0.8%;
	margin: 0 40px;
	background: #01fefe;
}

.block-4 {
	width: 2%;
	margin: 0 400px;
	background: #00fe00;
}

.block-5 {
	width: 0.8%;
	margin: 0 20px;
	background: #f4f315;
}

.block-6 {
	width: 2.8%;
	background: #fa04f9;
}
.hover-button {
    background-color: transparent;
    border: 1px solid #333;
    border-radius: 4px;
    color: lightgray;
    cursor: pointer;
    padding: 10px 20px;
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 50%;
    top: 75%;
    width: 25%;
    height: 15%;
    z-index: 999;
  }
  
  button:hover {
    background-color: white;
    color: black;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  