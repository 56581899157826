@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
.Form1 {
	  background:#2d3b36 url(https://i.ibb.co/vQ2KBzn/abstract-luxury-black-gradient-with-border-vignette-background-studio-backdrop-well-use-as-backdrop.jpg)no-repeat center center fixed;
  	-webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
    padding-top: 0px;
    height: 100vh;
}
.registration{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center ;
}

h2 {
    position: relative;
      color: #fff; 
      text-shadow: 1px 1px 0 rgba(0,0,0,0.4);
      font-size: 50px; 
      font-variant-caps: all-small-caps;
      text-align: left;   
      font-family: 'Oswald', sans-serif ;
      margin: 0px;
   }

form {
    margin-left:auto;
    margin-right:auto;
    width: 965px;
    height: 500px;
    padding:30px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; 
    overflow: hidden; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

textarea{
	background: rgba(0, 0, 0, 0.4); 
    width: 894px;
    height: 120px;
    border: none;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; 
    display: block;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    color: #fff;
    padding-left: 45px;
    padding-right: 20px;
    padding-top: 12px;
    margin-bottom: 20px;
    overflow: hidden;
}


.nameinput, .emailinput {
    width: 894px;
    height: 72px;
    border: none;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; 
    display: block;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

input[type=submit] {
    cursor: pointer;
}

input.nameinput {
	  background: rgba(0, 0, 0, 0.4); 
	  padding-left: 45px;
}

input.emailinput {
	  background: rgba(0, 0, 0, 0.4);
	  padding-left: 45px;
}

input.message {
	  background: rgba(0, 0, 0, 0.4);
	  padding-left: 45px;
}

::-webkit-input-placeholder {
	  color: #fff;
}

:-moz-placeholder{ 
    color: #fff; 
}

::-moz-placeholder {
    color: #fff;
}

:-ms-input-placeholder {  
	  color: #fff; 
}

input:focus, textarea:focus { 
	  background-color: rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 5px 1px rgba(255,255,255,.5);
    -webkit-box-shadow: 0 0 5px 1px rgba(255,255,255,.5);
    box-shadow: 0 0 5px 1px rgba(255,255,255,.5);
	  overflow: hidden; 
}

.btn {
	border: none;
	font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  width: 200px;
  height: 48px;
	color: #000;
	background: #fff;
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	position: relative;
  outline: none;
  box-shadow: 0 6px #cecece;
  border-radius: 5px;
  float: right;
  margin-right: 6px;
}

.btn:hover {
	background: #fff;
  outline: none;
  box-shadow: 0 4px #cecece;
	top: 2px;
}

.btn:active {
	background: #fff;
  outline: none;
  box-shadow: 0 0 #cecece;
	top: 6px;
}

.flat {
	border: none;
	cursor: pointer;
	display: inline-block;
	outline: none;
  position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}


.flat:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 3;
	font-size: 140%;
	width: 60px;
}


.flat {
   width: 960px !important;
   height: 48px;
   overflow: hidden;
   margin-bottom: 20px;
   background: url(http://www.jordancundiff.com/wp-content/uploads/2014/03/icon-dropdown.png) no-repeat right;
   }

@media only screen and ( min-width: 768px ) and ( max-width: 1035px ) {
  h1 { font-size: 80px; }
  form { width: 736px !important; }
    #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Subject.flat > select, #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Subject.flat { width: 731px !important; }
    .nameinput, .emailinput, #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Message > textarea { width: 666px !important; }
}

@media only screen and ( max-width: 804px ) {
    h1 { font-size: 50px; }
  form { width: 450px !important; }
   #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Subject.flat > select, #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Subject.flat { width: 445px !important; }
    .nameinput, .emailinput, #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Message > textarea { width: 380px !important; }
}

@media only screen and ( max-width: 517px ) {
     h1 { font-size: 30px; }
  form { width: 295px !important; }
  #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Subject.flat > select, #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Subject.flat { width: 290px !important; }
    .nameinput, .emailinput, #wpcf7-f156-p143-o1\20 formwrap > form > p > span.wpcf7-form-control-wrap.Message > textarea { width: 225px !important; }
  .btn { width: 110px; }
}