@media (max-width: 575.98px) {
  .HideOnMobile {
    display: none;
  }
  .ShowOnMobile {
    display: initial;
  }
}

@media (min-width: 576px) {
  .HideOnMobile {
    display: initial;
  }
  .ShowOnMobile {
    display: none;
  }
}